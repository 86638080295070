import React, { useEffect, useState } from "react";
import "./DialogInput.css";
import { Input } from "@ui5/webcomponents-react";

const DialogInput = ({
  type,
  placeholder,
  name,
  form,
  setForm,
  formValueState,
  formValueStateMessage,
  resetFormFieldValueState,
}) => {
  const [passwordIsOpen, setPasswordIsOpen] = useState(false);

  return (
    <div className="dialog-input-box">
      <div className="dialog-input-box-line">
        <input
          className={
            formValueState[name] === true
              ? "dialog-input-success"
              : formValueState[name] === false
              ? "dialog-input-error"
              : "dialog-input"
          }
          onChange={(event) => {
            if (type === "checkbox") return;
            let updated_value = event.target.value;
            let updated_form = form;
            updated_form[name] = updated_value;
            setForm(updated_form);
            resetFormFieldValueState(name);
          }}
          onInput={(event) => {
            if (type === "checkbox") return;
            let updated_value = event.target.value;
            let updated_form = form;
            updated_form[name] = updated_value;
            setForm(updated_form);
            resetFormFieldValueState(name);
          }}
          onClick={(event) => {
            if (type === "checkbox") {
              let updated_form = form;
              updated_form[name] = !form[name];
              setForm(updated_form);
              resetFormFieldValueState(name);
            }
          }}
          type={passwordIsOpen && type === "password" ? "text" : type ?? "text"}
          placeholder={placeholder ?? ""}
          name={name}
          checked={form[name]}
        />
        {type === "password" && (
          <img
            onClick={() => setPasswordIsOpen(!passwordIsOpen)}
            title={passwordIsOpen ? "Скрыть пароль" : "Показать пароль"}
            className="dialog-input-box-line-password-open"
            src={passwordIsOpen ? "/static/sherpa/eye_close.svg" : "/static/sherpa/eye.svg"}
          />
        )}
        {type === "checkbox" && <label for={name}>{placeholder}</label>}
      </div>
      {formValueState[name] === false && type !== "checkbox" && (
        <span className="dialog-input-error-message">
          {formValueStateMessage[name]}
        </span>
      )}
    </div>
  );
};

export default DialogInput;
