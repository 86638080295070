import React, { useState } from 'react'
import "./DialogComponent.css";
import { Dialog } from '@ui5/webcomponents-react';


const DialogComponent = ({ open, setOpen, header, footer, body }) => {
    return (
        <Dialog
            open={open}
            className="dialog-component"
            header={header}
            footer={footer}
            onBeforeClose={(e) => setOpen(false)}
        >
            {body}
        </Dialog>
    )
}

export default DialogComponent