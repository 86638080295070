import React, { useEffect, useState } from 'react'
import "./SelfRegistrationDialog.css";
import RegistrationDialog from '../RegistrationDialog';
import { FlexBox, Button } from '@ui5/webcomponents-react';
import DialogInput from '../../dialog-input/DialogInput';
import axios_instance from '../../../../../../requests/config_defaults';
import ResultDialog from '../../result-dialog/ResultDialog'

const SelfRegistrationDialog = ({ open, setOpen }) => {
    const emailValidator = require("email-validator");
    const [resultDialogIsOpen, setResultDialogIsOpen] = useState(false);

    const [form, setForm] = useState(
        {
            first_name: "",
            last_name: "",
            middle_name: "",
            email: "",
            password: "",
            repeat_password: "",
            personal_data_process_confirm: false
        }
    );

    const [formValueState, setFormValueState] = useState(
        {
            first_name: null,
            last_name: null,
            email: null,
            password: null,
            repeat_password: null,
            personal_data_process_confirm: null,
        }
    );

    const [formValueStateMessage, setFormValueStateMessage] = useState(
        {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            repeat_password: "",
            personal_data_process_confirm: "",
        }
    );

    const formInputs = [
        {
            type: "text",
            placeholder: "Ваше имя",
            name: "first_name"
        },
        {
            type: "text",
            placeholder: "Ваша фамилия",
            name: "last_name"
        },
        {
            type: "text",
            placeholder: "Ваше отчество",
            name: "middle_name"
        },
        {
            type: "email",
            placeholder: "Укажите вашу почту",
            name: "email"
        },
        {
            type: "password",
            placeholder: "Придумайте пароль",
            name: "password"
        },
        {
            type: "password",
            placeholder: "Повторите пароль",
            name: "repeat_password"
        },
        {
            type: "checkbox",
            placeholder: <span className="personal-data-process-confirm-checkbox">Я согласен(а) на <a className={"personal-data-process-confirm-checkbox-link"}>обработку персональных данных</a></span>,
            name: "personal_data_process_confirm"
        },
    ]

    function submitFormValidate(e) {
        let updated_form_value_state = { ...formValueState };
        let updated_form_value_state_message = { ...formValueStateMessage };
        let validation_status = true;
        for (let i in form) {
            if (!formValueState.hasOwnProperty(i)) {
                continue;
            }
            if (form[i] === "") {
                updated_form_value_state[i] = false;
                updated_form_value_state_message[i] = "Обязательное поле";
                validation_status = false;
            }
            else if (i === "email" && !emailValidator.validate(form[i])) {
                updated_form_value_state[i] = false;
                updated_form_value_state_message[i] = "Неверный формат почты";
                validation_status = false;
            }
            else if (i === "personal_data_process_confirm" && form[i] === false) {
                updated_form_value_state[i] = false;
                validation_status = false;
            }
            else if ((i === "password" || i === "repeat_password") && form.password !== form.repeat_password) {
                updated_form_value_state[i] = false;
                updated_form_value_state_message[i] = "Пароли не совпадают";
                validation_status = false;
            }
            else {
                updated_form_value_state[i] = null;
                updated_form_value_state_message[i] = "";
            }
        }
        setFormValueState(updated_form_value_state);
        setFormValueStateMessage(updated_form_value_state_message);

        if (validation_status) {
            performRegistration();
        }
    }

    function resetFormFieldValueState(field) {
        if (!formValueState.hasOwnProperty(field)) {
            return;
        }
        let updated_form_value_state = { ...formValueState };
        let updated_form_value_state_message = { ...formValueStateMessage };
        updated_form_value_state[field] = null;
        updated_form_value_state_message[field] = "";
        setFormValueState(updated_form_value_state);
        setFormValueStateMessage(updated_form_value_state_message);
    }

    async function performRegistration() {
        await axios_instance
            .post(`/registration`, form)
            .then(({ data, status }) => {
                setOpen(false)
                setResultDialogIsOpen(true)
            })
            .catch(error => {
                if (error?.response?.data?.email[0] === "Пользователь с таким Email уже существует.") {
                    setFormValueState({ ...formValueState, email: false })
                    setFormValueStateMessage({ ...formValueStateMessage, email: "Пользователь с таким email уже существует" })
                }
            });
    }

    return (
        <div>
            {open && (
                <RegistrationDialog
                    open={open}
                    setOpen={setOpen}
                    headerText="РЕГИСТРАЦИЯ"
                    footerText="ОТПРАВИТЬ"
                    submitHandler={submitFormValidate}
                    body={
                        <FlexBox direction="Column" className='dialog-inputs-box'>
                            {formInputs.map(input =>
                                <DialogInput
                                    type={input.type}
                                    placeholder={input.placeholder}
                                    name={input.name}
                                    form={form}
                                    setForm={setForm}
                                    formValueState={formValueState}
                                    formValueStateMessage={formValueStateMessage}
                                    resetFormFieldValueState={resetFormFieldValueState}
                                />
                            )}
                        </FlexBox>
                    }
                />
            )}
            {resultDialogIsOpen && (
                <ResultDialog
                    open={resultDialogIsOpen}
                    setOpen={setResultDialogIsOpen}
                    headerText="Информация принята!"
                    logo="/static/sherpa/mini_psg_logo.svg"
                    body={
                        <FlexBox alignItems="Center" direction="Column" className="dialog-inputs-box">
                            <span className="result-dialog-text">Уведомление о регистрации придет Вам на почту</span>
                            <Button
                                className="result-dialog-btn"
                                onClick={() => setResultDialogIsOpen(false)}
                            >
                                Готово
                            </Button>
                        </FlexBox>
                    }
                />
            )}
        </div>
    )
}

export default SelfRegistrationDialog