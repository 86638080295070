import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { getIcons } from "../requests/utils";

export const emailAtom = atomWithStorage("email", "");
export const activeCompanyAtom = atomWithStorage("activeCompany", {});
export const activeCompanyAddressAtom = atomWithStorage("activeCompanyAddress", {});
export const fullNameAtom = atomWithStorage("fullName", "");
export const isAdminAtom = atomWithStorage("isAdmin", false);
export const isImpersonatedAtom = atomWithStorage("isImpersonated", false);
export const marketIconsAtom = atomWithStorage("marketIconsAtom", getIcons());
export const userNotificationsAtom = atomWithStorage("userNotificationsAtom", [
    {
        text: "Получать уведомления о новостях",
        status: true
    },
    {
        text: "Получать уведомления о новых сообщениях",
        status: true
    },
    {
        text: "Получать уведомления об изменениях статуса заявки/заказа",
        status: true
    },
    {
        text: "Получать уведомления о важных обновлениях системы",
        status: true
    },
    {
        text: "Получать уведомления об оплате/возврате средств",
        status: true
    },
]);
