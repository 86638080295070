import React, { Suspense, useEffect, useState } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import Dashboard from "../dashboard/Dashboard"
import "@ui5/webcomponents-react/dist/Assets"

import { setLanguage } from "@ui5/webcomponents-base/dist/config/Language.js";
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import MainPage from "../1sherpa/main_page/MainPage.jsx";
import DocumentView from "../documents-view/DocumentsView.jsx";
import ProductsStocksView from "../products-stocks-view/ProductsStocksView.jsx";
import { Container } from "../container/Container.jsx";
// import UserProfile from "../user-profile/UserProfile.jsx";
import SalesView from "../sales-view/SalesView.jsx";
import RequireAuth from "../../hoc/RequireAuth.jsx";
import CalculationView from "../courier-plus/calculation/CalculationView.jsx";
import { CatalogView } from "../dashboard/catalog/CatalogView.jsx";
import OrdersView from "../orders-view/OrdersView.jsx";
import UserAgreement from "../user-agreement/UserAgreement.jsx";
import CompaniesView from "../companies-view/CompaniesView.jsx";
import Registration from "../registration/Registration.jsx";
import ExchangeView from "../exchange-view/ExchangeView.jsx";
import { atom } from "jotai";
import AdminOnly from "../../hoc/AdminOnly.jsx";
import UsersAdminView from "../admin-panel/users-admin-panel/UsersAdminView.jsx";
import ProductsAdminView from "../admin-panel/products-admin-panel/ProductsAdminView.jsx";
import ProductSearcherWithWizardMain from "../product-searcher-with-wizard/ProductSearcherWithWizardMain.jsx";
import Authorization from "../authorization/Authorization.jsx";
import EmailExchangeView from "../auto-email-exchange-view/EmailExchangeView.jsx";
import EntityPermissionsView from "../admin-panel/entity-permissions-view/EntityPermissionsView.jsx";
import UserCompanyView from "../user-companies-view/UserCompanyView.jsx";
import ExchangeEmailReview from "../admin-panel/exchange-email-review/ExchangeEmailReview";
import MarketSettings from "../market_settings/MarketSettings.jsx";
import OrdersDeliveryAdminManageView from "../admin-panel/orders-delivery-admin-manage-view/OrdersDeliveryAdminManageView.jsx";
import EntityRemnantsView from "../admin-panel/admin-entities-remnants/EntityRemnantsView.jsx";
import {UserProfile} from "../1sherpa/user-profile/UserProfile.jsx";
import UserProfileCard from "../1sherpa/user-profile/user-profile-card/UserProfileCard.jsx"
import {UserProfileMain} from "../1sherpa/user-profile/user-profile-main/UserProfileMain.jsx"
import { UserProfileNotifications } from "../1sherpa/user-profile/user-profile-notifications/UserProfileNotifications.jsx";
setTheme("sap_horizon");
setLanguage("ru");

const DefaultRoute = () => {
    const nav = useNavigate()
    useEffect(() => {
        nav("/exzamarket")
    }, [])
    return <></>
}

const Router = () => {
    const [addressesData, setAddressesData] = useState([])
    const nav = useNavigate()

    return (
        <>
            <Suspense>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="profile/*" element={<UserProfile />} >
                        <Route path="main" element={<UserProfileMain />} />
                        <Route path="info" element={<UserProfileCard />} />
                        <Route path="notifications" element={<UserProfileNotifications />} /> 
                        {/* <Route path="settings" element={<UserProfileSettings />} /> */}
                    </Route>
                </Routes>
            </Suspense>
        </>
    )
}

export default Router
