import React, { useEffect, useState, useRef } from "react";
import "./DialogSelect.css";
import { Icon } from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/navigation-up-arrow.js";
import "@ui5/webcomponents-icons/dist/navigation-down-arrow.js";

const DialogSelect = ({
  options,
  placeholder,
  name,
  form,
  setForm,
  formValueState,
  formValueStateMessage,
  resetFormFieldValueState,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectReversed, setSelectReversed] = useState(false);

  const handleSelect = (optionValue) => {
    setIsOpen(false);
    let updated_form = form;
    updated_form[name] = optionValue;
    setForm(updated_form);
    setSelectReversed(false);
    resetFormFieldValueState(name);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setSelectReversed(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dropdownRef.current) {
      const dropdown = dropdownRef.current.querySelector('.dialog-select-options');
      if (dropdown) {
        const rect = dropdown.getBoundingClientRect();
        const viewportHeight = window.innerHeight - rect.top;
        if (rect.bottom > viewportHeight) {
          dropdown.style.top = 'auto';
          dropdown.style.bottom = '100%';
          setSelectReversed(true);
        } else {
          dropdown.style.top = '101%';
          dropdown.style.bottom = 'auto';
        }
      }
    }
  }, [isOpen]);

  return (
    <div>
      <div
        className={
          isOpen ? (!selectReversed ? "dialog-select-is-open" : "dialog-select-is-open-reversed") :
          formValueState[name] === true ? "dialog-select-success" : 
          formValueState[name] === false ? "dialog-select-error" : "dialog-select"
        }
        onClick={() => {
          setIsOpen(!isOpen);
          setSelectReversed(false);
        }}
        ref={dropdownRef}
      >
        <div
          className={
            `${form[name] !== "" ? "dialog-select-input" : "dialog-select-input-placeholder"} `
          }
        >
          {options?.find((option) => option?.id === form[name])?.name || placeholder}
        </div>
        <Icon
          className="dialog-select-nav-icon"
          name={isOpen ? "navigation-up-arrow" : "navigation-down-arrow"}
        />
        {isOpen && (
          <div className={!selectReversed ? "dialog-select-options" : "dialog-select-options-reversed"}>
            {options?.filter(option => option.name !== form[name]).map(option => (
              <div
                key={option?.id}
                className="dialog-select-option"
                onClick={() => handleSelect(option?.id)}
              >
                {option.name}
              </div>
            ))}
          </div>
        )}
      </div>
      {formValueState[name] === false && <span className="dialog-select-error-message">{formValueStateMessage[name]}</span>}
    </div>
  );
};

export default DialogSelect;
