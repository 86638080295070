import React, { useEffect } from 'react'
import "./ResultDialog.css";
import DialogHeader from '../dialog-header/DialogHeader';
import DialogFooter from '../dialog-footer/DialogFooter';
import DialogComponent from '../DialogComponent';


const ResultDialog = ({ open, setOpen, headerText, body, logo }) => {
    return (
        <DialogComponent
            open={open}
            setOpen={setOpen}
            className="result"
            header={<DialogHeader logo={logo} text={headerText} setOpen={setOpen} />}
            body={body}
        />
    )
}

export default ResultDialog