import React, { useEffect, useState } from "react";
import "./UserProfileMainBodyElem.css";

const UserProfileMainBodyElem = (props) => {

  return (
    <div className="user-profile-main-body-elem-container">
      <div className="user-profile-main-body-elem-container-info">
        <span className="user-profile-main-body-elem-container-info-title">
          {props.title}
        </span>
        <span className="user-profile-main-body-elem-container-info-text">
          {props.text}
        </span>
        <span className="user-profile-main-body-elem-container-info-btn">
          {props.btnText}
        </span>
      </div>
      <div className="user-profile-main-body-elem-container-logo">
        <img src={props.logo} />
      </div>
    </div>
  );
};

export { UserProfileMainBodyElem };
