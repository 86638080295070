import React, { useState, useEffect } from 'react'
import "./MainPage.css";
import Header from './Header/Header';
import Body from './Body/Body';
import { checkIsAuthenticated } from '../../../requests/authorization';

const MainPage = () => {
    const [isAuth, setIsAuth] = useState(false);

    const isAuthenticated = async () => {
        let status = await checkIsAuthenticated();
        setIsAuth(status);
    }

    useEffect(() => {
        isAuthenticated();
    }, []);

    useEffect(() => {
        console.log(isAuth)
    }, [isAuth]);

    return (
        <div className='main-page-main-container'>
            <Header isAuth={isAuth} setIsAuth={setIsAuth}/>
            <Body />
        </div>
    )
}

export default MainPage