import React, { useEffect } from 'react'
import "./RegistrationDialog.css";
import DialogHeader from '../dialog-header/DialogHeader';
import DialogFooter from '../dialog-footer/DialogFooter';
import DialogComponent from '../DialogComponent';


const RegistrationDialog = ({ open, setOpen, headerText, footerText, body, submitHandler }) => {
    return (
        <DialogComponent
            open={open}
            setOpen={setOpen}
            className="registration"
            footer={<DialogFooter text={footerText} submitHandler={submitHandler} slot="footer" />}
            header={<DialogHeader text={headerText} setOpen={setOpen} slot="header" />}
            body={body}
        />
    )
}

export default RegistrationDialog