import "./AskQuestion.css";
import { Button, IllustratedMessage, Input, SuggestionItem } from "@ui5/webcomponents-react";
import { useState, useEffect, useRef } from "react";
import "@ui5/webcomponents-fiori/dist/illustrations/NoEntries.js"

const AskQuestion = () => {
    const [inputCode, setInputCode] = useState("");
    const [inputError, setInputError] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [activeProduct, setActiveProduct] = useState("")

    const [searchError, setSearchError] = useState(false)
    const [searchErrorMessage, setSearchErrorMessage] = useState("")

    const [buttonWidth, setButtonWidth] = useState('70%');
    const [showClearIcon, setShowClearIcon] = useState(false);
    const [buttonDisplay, setButtonDisplay] = useState('block');
    const [inputFocus, setInputFocus] = useState(false)

    return (
        <div className="main-page-body-container-content-right-body">
            <IllustratedMessage name="NoEntries" />
        </div>
    );
};

export default AskQuestion;
