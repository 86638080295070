import "./UserProfileCard.css";
import { Button, Select, Option, FlexBox, MessageBox, ThemeProvider } from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/camera.js";
import UserProfileCardOneLine from "./user-profile-card-one-line/UserProfileCardOneLine";
import { useState, useEffect } from "react";
import ResultDialog from "../../main_page/DialogComponent/result-dialog/ResultDialog";
import axios_instance from "../../../../requests/config_defaults";

const UserProfileCard = () => {
  const [resultDialogIsOpen, setResultDialogIsOpen] = useState(false);
  const [formInputs, setFormInputs] = useState([]);
  const [userPhoto, setUserPhoto] = useState("/static/sherpa/default_user_photo.jpg");
  const [bigPhotoError, setBigPhotoError] = useState(false);
  const [uploadedPhoto, setUploadedPhoto] = useState({
    photo: null,
  });
  const [photoDeleteBoxIsOpen, setPhotoDeleteBoxIsOpen] = useState(false);

  const photoUpdate = async () => {
    const formData = new FormData();
    uploadedPhoto.photo && formData.append("photo", uploadedPhoto.photo?.photo);
    await axios_instance
      .put(`photo/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        getMe();
        setUploadedPhoto((prev) => ({
          ...prev,
          photo: null,
        }));
      })
      .catch((error) => {});
  };

  const photoDelete = async () => {
    await axios_instance
      .put(`photo/delete`)
      .then(({ data }) => {
        getMe();
      })
      .catch((error) => {});
  };

  const handlePhotoUpdate = (photo) => {
    const file = photo;
    const maxSizeInBytes = 1 * 1024 * 1024;

    if (file) {
      if (file.size > maxSizeInBytes) {
        setBigPhotoError(true);
      }
    }
  };

  const getMe = async () => {
    await axios_instance
      .get("/getMe")
      .then((response) => {
        {
          response?.data?.user?.photo ? (
            setUserPhoto(response?.data?.user?.photo)
          ) : (
            setUserPhoto("/static/sherpa/default_user_photo.jpg")
          );
        }
        const telephones = response?.data?.user?.telephones.map(
          (telephone, index) => {
            return {
              id: telephone.id,
              title: "Телефон",
              value: telephone.phone_number,
              name: `phone_number_${index}`,
              changeable: true,
              required: false,
              index: index,
              changed: false,
            };
          }
        );
        if (telephones.length === 0) {
          var now = new Date();
          telephones.push({
            id: null,
            title: "Телефон",
            value: "",
            name: `phone_number_new${now}`,
            changeable: true,
            required: false,
            index: 0,
            changed: false,
          });
        }
        const social_medias = response?.data?.user?.social_profiles.map(
          (media, index) => {
            return {
              id: media.id,
              title: "Социальная сеть",
              value: media.name,
              name: `social_media_${index}`,
              media: media?.social_media,
              changeable: true,
              required: false,
              index: index,
              changed: false,
            };
          }
        );
        if (social_medias.length === 0) {
          let now = new Date();
          social_medias.push({
            id: null,
            title: "Социальная сеть",
            value: "",
            name: `social_media_new_${now}`,
            media: null,
            changeable: true,
            required: false,
            index: 0,
            changed: false,
          });
        }
        const main_info = [
          {
            title: "Фамилия",
            value: response?.data?.user?.last_name,
            name: "last_name",
            changeable: true,
            required: true,
            changed: false,
          },
          {
            title: "Электронная почта",
            value: response?.data?.user?.email,
            name: "email",
            changeable: true,
            required: true,
            changed: false,
          },
          {
            title: "Имя",
            value: response?.data?.user?.first_name,
            name: "first_name",
            changeable: true,
            required: true,
            changed: false,
          },
          {
            title: "Подразделение",
            value: response?.data?.user?.department,
            name: "department",
            changeable: false,
            required: false,
            changed: false,
          },
          {
            title: "Отчество",
            value: response?.data?.user?.middle_name,
            name: "middle_name",
            changeable: true,
            required: false,
            changed: false,
          },
          {
            title: "Должность",
            value: response?.data?.user?.job_position,
            name: "job_position",
            changeable: false,
            required: false,
            changed: false,
          },
          {
            title: "Дата рождения",
            value: response?.data?.user?.birthday,
            name: "birthday",
            changeable: true,
            required: false,
            changed: false,
          },
        ];
        setFormInputs([...main_info, ...telephones, ...social_medias]);
      })
      .catch((error) => {
        setUserPhoto("/static/sherpa/default_user_photo.jpg")
      });
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div className="user-profile-card-main-content">
      <div className="user-profile-card-main-content-photo">
        {uploadedPhoto.photo ? (
          <div class="user-profile-photo-container">
              <img src={uploadedPhoto.photo.photo_interface} class="user-profile-photo-background" alt="Background" />
              <img src={uploadedPhoto.photo.photo_interface} class="user-profile-photo" alt="Profile Photo" />
          </div>
        ) : (
          <div class="user-profile-photo-container">
              <img src={userPhoto} class="user-profile-photo-background" alt="Background" />
              <img src={userPhoto} class="user-profile-photo" alt="Profile Photo" />
          </div>
        )}
        {bigPhotoError && (
          <span className="big-image-error-text">
            *Размер загружаемого фото не должен превышать 1МБ.
          </span>
        )}
        {!uploadedPhoto.photo ? (
          <Button
            tooltip="Размер загружаемого фото не должен превышать 1МБ"
            className="user-profile-card-main-content-photo-download-btn"
            icon="camera"
            onClick={() => {
              document.getElementById('photoUpload').click();
            }}
          >
            Загрузить фото
          </Button>
        ) : (
          <Button
            className="user-profile-card-main-content-photo-download-btn"
            disabled={bigPhotoError ? true : false}
            onClick={() => {
              photoUpdate();
            }}
          >
            Сохранить
          </Button>
        )}
        <input
          style={{ display: "none" }}
          id="photoUpload"
          type="file"
          accept="image/*"
          onChange={(e) => {
            setUploadedPhoto((prev) => ({
              ...prev,
              photo: {
                photo_interface: URL.createObjectURL(e.target.files[0]),
                photo: e.target.files[0],
              },
            }));
            handlePhotoUpdate(e.target.files[0]);
          }}
        />
        {!uploadedPhoto.photo ? (
          <ThemeProvider>
            <Button 
              className="user-profile-card-main-content-photo-delete-btn"
              onClick={() => setPhotoDeleteBoxIsOpen(true)}
            >
              Удалить
            </Button>
            <MessageBox
              open={photoDeleteBoxIsOpen}
              type="Confirm"
              onClose={() => {
                setPhotoDeleteBoxIsOpen(false);
              }}
              actions={[
                <Button
                  design="Emphasized"
                  onClick={() => {
                    photoDelete();
                    setPhotoDeleteBoxIsOpen(false);
                  }}
                >
                  Удалить
                </Button>,
                <Button
                  onClick={() => {
                    setPhotoDeleteBoxIsOpen(false);
                  }}
                >
                  Отмена
                </Button>,
              ]}
            >
              Нажмите "Удалить", чтобы подтвердить удаление.
            </MessageBox>
          </ThemeProvider>
        ) : (
          <Button
            className="user-profile-card-main-content-photo-delete-btn"
            onClick={() => {
              setUploadedPhoto((prev) => ({
                ...prev,
                photo: null,
              }));
              setBigPhotoError(false);
            }}
          >
            Отменить
          </Button>
        )}
      </div>
      <div className="user-profile-card-main-content-info">
        {formInputs.map((line) => (
          <UserProfileCardOneLine
            id={line.id}
            title={line.title}
            media={line.media}
            value={line.value}
            name={line.name}
            index={line.index}
            changeable={line.changeable}
            required={line.required}
            formInputs={formInputs}
            setFormInputs={setFormInputs}
            getMe={getMe}
          />
        ))}
      </div>
      <Select className="user-profile-card-main-container-lang-select-btn">
        <Option>RU</Option>
      </Select>
      <Button
        icon="decline"
        design="Transparent"
        tooltip="Закрыть"
        className="user-profile-card-main-container-close-btn"
      />
      {/* <Button
        onClick={() => {
          submitFormValidate();
        }}
        tooltip="Сохранить"
        className="user-profile-card-main-container-save-btn"
      >
        Сохранить изменения
      </Button> */}
      <ResultDialog
        open={resultDialogIsOpen}
        setOpen={setResultDialogIsOpen}
        headerText="Данные успешно сохранены!"
        body={
          <FlexBox
            alignItems="Center"
            direction="Column"
            className="dialog-inputs-box"
          >
            <Button
              className="result-dialog-btn"
              onClick={() => setResultDialogIsOpen(false)}
            >
              Готово
            </Button>
          </FlexBox>
        }
      />
    </div>
  );
};

export default UserProfileCard;
