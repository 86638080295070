import React, { useEffect, useState } from "react";
import "./UserProfileMain.css";
import { UserProfileMainBodyElem } from "./user-profile-main-body-elem/UserProfileMainBodyElem";
import axios_instance from "../../../../requests/config_defaults";

const UserProfileMain = (props) => {
  const [userData, setUserData] = useState(null);

  const getMe = async () => {
    await axios_instance
      .get("/getMe")
      .then((response) => {
        setUserData(response.data.user);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div className="user-profile-main-container">
      <div className="user-profile-main-content">
        <div className="user-profile-main-content-header">
          <h1 className="user-profile-main-content-header-title">
            Добро пожаловать, {userData?.first_name}!
          </h1>
          <span className="user-profile-main-content-header-text">
            Укажите более подробную информацию о себе в настройках профиля, чтобы Вам было еще
            удобнее получать персонализированную информацию о работе. 
            <span className="user-profile-main-content-header-text-a">
              Перейти в настройки...
            </span>
          </span>
        </div>
        <div className="user-profile-main-content-body">
          <UserProfileMainBodyElem
            title="Задачи"
            text="Получить персонализированные задачи, соответствующие Вашим настройкам"
            btnText="Управление задачами"
            logo="/static/sherpa/tasks_logo.svg"
          />
          <UserProfileMainBodyElem
            title="Отчеты"
            text="Получить персонализированные отчеты, соответствующие Вашим настройкам"
            btnText="Управление отчетами"
            logo="/static/sherpa/reports.svg"
          />
          <UserProfileMainBodyElem
            title="Динамика работы"
            text="Получить персонализированные данные о Вашей работе"
            btnText="Просмотреть данные"
            logo="/static/sherpa/work_dynamics.svg"
          />
        </div>
      </div>
    </div>
  );
};

export { UserProfileMain };
