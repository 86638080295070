import { useAtom } from "jotai";
import "./UserProfileHeader.css";
import { useState, useEffect } from "react";
import { isAdminAtom } from "../../../../hoc/GlobalAtoms";
import axios_instance from "../../../../requests/config_defaults";
import { useNavigate } from "react-router-dom";

const UserProfileHeader = () => {
  const [isAdmin, setIsAdmin] = useAtom(isAdminAtom);
  const nav = useNavigate();
  const [userData, setUserData] = useState({});

  const handleClickLogOutButton = async () => {
    await axios_instance
      .get("/logout")
      .then((response) => {
        setIsAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMe = async () => {
    await axios_instance
      .get("/getMe")
      .then((response) => {
        setUserData(response?.data?.user)
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div className="user-profile-header-main">
      <div className="user-profile-header-left-side">
        <a href="/" className="user-profile-main-logo-a">
          <img
            alt="PSG"
            className="main-logo"
            src="/static/sherpa/small_psg_logo.svg"
          />
        </a>
        <a href="/profile/main" className="user-profile-header-left-side-text">
          Личный кабинет
        </a>
      </div>
      <div className="user-profile-header-right-side">
        <img
          onClick={() => {
            nav("/profile/info")
          }}
          src={userData.photo ? userData.photo : "/static/sherpa/default_user_photo.jpg"}
          className="user-profile-header-right-side-user-photo"
        />
        <div
          onClick={() => {
            nav("/profile/info")
          }}
          className="user-profile-header-right-side-user-info"
        >
          <div className="user-profile-header-right-side-user-info-name">
            {`${userData?.first_name} ${userData?.last_name}`}
          </div>
          <div className="user-profile-header-right-side-user-info-email">
          {userData?.email}
          </div>
        </div>
        <hr className="vertical-hr" />
        <img
          src="/static/sherpa/bell.svg"
          className="user-profile-header-right-side-exit-logo"
        />
        <hr className="vertical-hr" />
        <img
          onClick={() => {
            handleClickLogOutButton();
            nav("/")
          }}
          src="/static/sherpa/exit_logo.svg"
          className="user-profile-header-right-side-exit-logo"
        />
      </div>
    </div>
  );
};

export default UserProfileHeader;
