import React, { useEffect, useState } from "react";
import "./UserLoginDialog.css";
import RegistrationDialog from "../RegistrationDialog";
import { FlexBox, Button } from "@ui5/webcomponents-react";
import DialogInput from "../../dialog-input/DialogInput";
import axios_instance from "../../../../../../requests/config_defaults";
import ResultDialog from "../../result-dialog/ResultDialog";
import { isAdminAtom } from "../../../../../../hoc/GlobalAtoms";
import { useAtom } from "jotai";

const UserLoginDialog = ({ open, setOpen, setIsAuth }) => {
  const [isAdmin, setIsAdmin] = useAtom(isAdminAtom);
  const emailValidator = require("email-validator");
  const [resetPasswordIsOpen, setResetPasswordIsOpen] = useState(false);
  const [resetPasswordResultIsOpen, setResetPasswordResultIsOpen] =
    useState(false);
  const [incorectLoginData, setIncorectLoginData] = useState(false);
  const [manyIncorrectLoginError, setManyIncorrectLoginError] = useState(false);
  const [
    manyIncorrectLoginErrorResultIsOpen,
    setManyIncorrectLoginErrorResultIsOpen,
  ] = useState(false);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [formValueState, setFormValueState] = useState({
    email: null,
    password: null,
  });

  const [formValueStateMessage, setFormValueStateMessage] = useState({
    email: "",
    password: "",
  });

  const formInputs = [
    {
      type: "email",
      placeholder: "Электронная почта",
      name: "email",
    },
    {
      type: "password",
      placeholder: "Пароль",
      name: "password",
    },
  ];

  const [resetForm, setResetForm] = useState({
    email: "",
  });

  const [resetFormValueState, setResetFormormValueState] = useState({
    email: null,
  });

  const [resetFormValueStateMessage, setResetFormValueStateMessage] = useState({
    email: "",
  });

  const resetFormInputs = [
    {
      type: "email",
      placeholder: "Укажите Вашу почту",
      name: "email",
    },
  ];

  function submitFormValidate(e) {
    let updated_form_value_state = { ...formValueState };
    let updated_form_value_state_message = { ...formValueStateMessage };
    let validation_status = true;
    for (let i in form) {
      if (!formValueState.hasOwnProperty(i)) {
        continue;
      }
      if (form[i] === "") {
        updated_form_value_state[i] = false;
        updated_form_value_state_message[i] = "Обязательное поле";
        validation_status = false;
      } else if (i === "email" && !emailValidator.validate(form[i])) {
        updated_form_value_state[i] = false;
        updated_form_value_state_message[i] = "Неверный формат почты";
        validation_status = false;
      }
    }
    setFormValueState(updated_form_value_state);
    setFormValueStateMessage(updated_form_value_state_message);

    if (validation_status) {
      performIdentification();
    }
  }

  function resetFormFieldValueState(field) {
    if (!formValueState.hasOwnProperty(field)) {
      return;
    }
    let updated_form_value_state = { ...formValueState };
    let updated_form_value_state_message = { ...formValueStateMessage };
    updated_form_value_state[field] = null;
    updated_form_value_state_message[field] = "";
    setFormValueState(updated_form_value_state);
    setFormValueStateMessage(updated_form_value_state_message);
  }

  const isIncorrectLoginData = async () => {
    setIncorectLoginData(true);
    setFormValueState((prevState) => ({
      ...prevState,
      email: false,
      password: false,
    }));
  };

  async function performIdentification() {
    await axios_instance
      .get(`/identification/${form.email}`)
      .then(({ data, status }) => {
        switch (data?.error_code) {
          case "NO_USER":
            isIncorrectLoginData();
            break;
          case null || undefined:
            performAuthentication();
            break;
        }
      })
      .catch((error) => { });
  }

  async function performAuthentication() {
    await axios_instance
      .post("/login", {
        email: form.email,
        password: form.password,
      })
      .then((response) => {
        if (response.data.status) {
          getMe();
          setOpen(false);
          setIsAuth(true);
        };
        isIncorrectLoginData();
      })
      .catch((error) => {
        isIncorrectLoginData();
      });
  }

  const getMe = async () => {
    await axios_instance
      .get("/getMe")
      .then((response) => {
        setIsAdmin(response.data.is_admin || response.data.user.is_superuser);
      })
      .catch((error) => { });
  };

  async function passwordRecoveryDataSend() {
    await axios_instance
      .post(
        "/password/recovery",
        {
          email: resetForm.email
        }
      )
      .then((response) => {
        console.log(response.data);
        setResetPasswordIsOpen(false);
        setResetPasswordResultIsOpen(true);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function submitFormValidatePasswordRecovery(e) {
    let updated_form_value_state = { ...resetFormValueState };
    let updated_form_value_state_message = { ...resetFormValueStateMessage };
    let validation_status = true;
    for (let i in resetForm) {
      if (!resetFormValueState.hasOwnProperty(i)) {
        continue;
      }
      if (resetForm[i] === "") {
        updated_form_value_state[i] = false;
        updated_form_value_state_message[i] = "Обязательное поле";
        validation_status = false;
      } else if (i === "email" && !emailValidator.validate(resetForm[i])) {
        updated_form_value_state[i] = false;
        updated_form_value_state_message[i] = "Неверный формат почты";
        validation_status = false;
      }
    }
    setResetFormormValueState(updated_form_value_state);
    setResetFormValueStateMessage(updated_form_value_state_message);

    if (validation_status) {
      passwordRecoveryDataSend();
    }
  }

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div>
      {open && (
        <RegistrationDialog
          open={open}
          setOpen={setOpen}
          headerText={
            !incorectLoginData ? (
              <FlexBox>ВХОД</FlexBox>
            ) : (
              <FlexBox
                justifyContent="Center"
                alignItems="Center"
                direction="Column"
                className="user-login-text"
              >
                <span className="user-login-text-big">ВХОД</span>
                <FlexBox
                  justifyContent="Center"
                  alignItems="Center"
                  direction="Column"
                >
                  <span className="user-login-text-small">
                    Логин или пароль указаны неверно.
                  </span>
                  <span className="user-login-text-small">
                    Проверьте правильность введенных данных.
                  </span>
                </FlexBox>
              </FlexBox>
            )
          }
          footerText="Войти"
          submitHandler={submitFormValidate}
          body={
            <FlexBox direction="Column" className="dialog-inputs-box">
              {formInputs.map((input) => (
                <DialogInput
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.name}
                  form={form}
                  setForm={setForm}
                  formValueState={formValueState}
                  formValueStateMessage={formValueStateMessage}
                  resetFormFieldValueState={resetFormFieldValueState}
                />
              ))}
              <span
                onClick={() => {
                  setResetPasswordIsOpen(true);
                  setOpen(false);
                }}
                className="reset-password-btn"
              >
                Забыли логин или пароль?
              </span>
            </FlexBox>
          }
        />
      )}
      {resetPasswordIsOpen && (
        <ResultDialog
          open={resetPasswordIsOpen}
          setOpen={setResetPasswordIsOpen}
          logo="/static/sherpa/mini_psg_logo.svg"
          body={
            <FlexBox
              alignItems="Center"
              direction="Column"
              className="dialog-inputs-box"
            >
              {resetFormInputs.map((input) => (
                <DialogInput
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.name}
                  form={resetForm}
                  setForm={setResetForm}
                  formValueState={resetFormValueState}
                  formValueStateMessage={resetFormValueStateMessage}
                  resetFormFieldValueState={setResetFormormValueState}
                />
              ))}
              <span className="password-reset-dialog-text">
                Контрольная строка для смена пароля, а также Ваши
                регистрационные данные, будут высланы Вам по E-Mail.
              </span>
              <FlexBox justifyContent="End" style={{ width: "100%" }}>
                <Button
                  icon="arrow-right"
                  iconEnd
                  className="password-reset-dialog-btn"
                  onClick={() => {
                    submitFormValidatePasswordRecovery();
                  }}
                >
                  <span className="password-reset-dialog-btn-text">
                    Отправить
                  </span>
                </Button>
              </FlexBox>
            </FlexBox>
          }
        />
      )}
      {resetPasswordResultIsOpen && (
        <ResultDialog
          open={resetPasswordResultIsOpen}
          setOpen={setResetPasswordResultIsOpen}
          headerText="Проверьте свою почту"
          logo="/static/sherpa/mini_psg_logo.svg"
          body={
            <FlexBox
              alignItems="Center"
              direction="Column"
              className="dialog-inputs-box"
            >
              <span className="password-reset-dialog-text">
                Уведомление о сбросе пароля отправлено на указанную Вами почту.
                Перейдите по ссылке в сообщении для дальнейших действий.
              </span>
              <FlexBox justifyContent="Center" style={{ width: "100%" }}>
                <Button
                  className="password-reset-dialog-btn"
                  onClick={() => {
                    setResetPasswordResultIsOpen(false);
                  }}
                >
                  <span className="password-reset-dialog-btn-text">Готово</span>
                </Button>
              </FlexBox>
            </FlexBox>
          }
        />
      )}
      {manyIncorrectLoginError && (
        <ResultDialog
          open={manyIncorrectLoginError}
          setOpen={setManyIncorrectLoginError}
          headerText={
            <FlexBox
              justifyContent="Center"
              alignItems="Center"
              direction="Column"
              className="user-login-many-incorrect-login-header"
            >
              <span className="user-login-text-big-many-incorrect-login-header-title">
                Вход заблокирован
              </span>
              <span className="user-login-text-small">
                Слишком много раз введен неправильный логин или пароль.
              </span>
            </FlexBox>
          }
          body={
            <FlexBox
              alignItems="Center"
              direction="Column"
              className="dialog-inputs-box"
            >
              {resetFormInputs.map((input) => (
                <DialogInput
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.name}
                  form={resetForm}
                  setForm={setResetForm}
                  formValueState={resetFormValueState}
                  formValueStateMessage={resetFormValueStateMessage}
                  resetFormFieldValueState={setResetFormormValueState}
                />
              ))}
              <span className="password-reset-dialog-text">
                Для решения проблемы укажите адрес Вашей электронной почты.
              </span>
              <FlexBox justifyContent="End" style={{ width: "100%" }}>
                <Button
                  icon="arrow-right"
                  iconEnd
                  className="password-reset-dialog-btn"
                  onClick={() => {
                    // submitFormValidate();
                    setManyIncorrectLoginError(false);
                    setManyIncorrectLoginErrorResultIsOpen(true);
                  }}
                >
                  <span className="password-reset-dialog-btn-text">
                    Отправить
                  </span>
                </Button>
              </FlexBox>
            </FlexBox>
          }
        />
      )}
      {manyIncorrectLoginErrorResultIsOpen && (
        <ResultDialog
          open={manyIncorrectLoginErrorResultIsOpen}
          setOpen={setManyIncorrectLoginErrorResultIsOpen}
          headerText="Проверьте свою почту"
          logo="/static/sherpa/mini_psg_logo.svg"
          body={
            <FlexBox
              alignItems="Center"
              direction="Column"
              className="dialog-inputs-box"
            >
              <span className="password-reset-dialog-text">
                Информация о дальнейших действиях отправлена на указанную Вами
                почту.
              </span>
              <FlexBox justifyContent="Center" style={{ width: "100%" }}>
                <Button
                  className="password-reset-dialog-btn"
                  onClick={() => {
                    setManyIncorrectLoginErrorResultIsOpen(false);
                  }}
                >
                  <span className="password-reset-dialog-btn-text">Готово</span>
                </Button>
              </FlexBox>
            </FlexBox>
          }
        />
      )}
    </div>
  );
};

export default UserLoginDialog;
