import "./UserProfileCardOneLine.css";
import {
  ActionSheet,
  Button,
  Input,
  Option,
  Select,
  ThemeProvider,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/edit.js";
import React, { useState, useRef, useEffect } from "react";
import axios_instance from "../../../../../requests/config_defaults";
import { useAtom } from "jotai";
import { isAdminAtom } from "../../../../../hoc/GlobalAtoms";

const UserProfileCardOneLine = (props) => {
  const [actionSheetIsOpen, setActionSheetIsOpen] = useState(
    props.name.startsWith("social_media_new_one") ? true : false
  );
  const [isAdmin] = useAtom(isAdminAtom);
  const emailValidator = require("email-validator");
  const [editMode, setEditMode] = useState(
    props.name.startsWith("phone_number_new_one") |
      props.name.startsWith("social_media_new_one")
      ? true
      : false
  );
  const inputRef = useRef(null);
  const actionSheetRef = useRef(null);
  const [newValue, setNewValue] = useState(props?.value);
  const [choosenSocialMedia, setChoosenSocialMedia] = useState();
  const [isValid, setIsValid] = useState(true);
  const [vkData, setVkData] = useState({});
  const [tgData, setTgData] = useState({});
  const [valueState, setValueState] = useState(false);
  const [valueStateMessage, setValueStateMessage] = useState("");

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const getSocialMedia = async () => {
    await axios_instance
      .get("/get/all/social_media")
      .then((response) => {
        response?.data.map((media) => {
          if (media.name === "Вконтакте") {
            setVkData(media);
          }
          if (media.name === "Телеграмм") {
            setTgData(media);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addPhoneNumberField = () => {
    var now = new Date();
    props.setFormInputs((prevFormInputs) => [
      ...prevFormInputs,
      {
        title: "Номер телефона",
        value: "",
        name: `phone_number_new_one_${now}`,
        changeable: true,
        required: false,
      },
    ]);
  };

  const addSocialMediaField = () => {
    var now = new Date();
    props.setFormInputs((prevFormInputs) => [
      ...prevFormInputs,
      {
        title: "Социальная сеть",
        value: "",
        name: `social_media_new_one_${now}`,
        changeable: true,
        required: false,
      },
    ]);
  };

  const updateMe = async (updatedUserData) => {
    await axios_instance
      .patch("/updateMe", updatedUserData)
      .then((response) => {
        setEditMode(!editMode);
        props.getMe();
      })
      .catch((error) => {
        setIsValid(false);
        setValueState(true);
        setValueStateMessage("Ошибка при сохранении изменений");
      });
  };

  const createSocialMedia = async () => {
    await axios_instance
      .post(`/socialProfile/create`, {
        name: newValue,
        social_media: String(choosenSocialMedia),
      })
      .then((response) => {
        setEditMode(!editMode);
        props.getMe();
      })
      .catch((error) => {
        setIsValid(false);
        setValueState(true);
        setValueStateMessage("Ошибка при создании социальной сети");
      });
  };

  const createPhoneNumber = async () => {
    await axios_instance
      .post(`/telephone/create`, {
        phone_number: String(newValue),
      })
      .then((response) => {
        setEditMode(!editMode);
        props.getMe();
      })
      .catch((error) => {
        setIsValid(false);
        setValueState(true);
        setValueStateMessage("Неверный формат номера телефона");
      });
  };

  const updatePhoneNumber = async (id) => {
    await axios_instance
      .patch(`/telephone/update/${id}`, { phone_number: String(newValue) })
      .then((response) => {
        setEditMode(!editMode);
        props.getMe();
      })
      .catch((error) => {
        setIsValid(false);
        setValueState(true);
        setValueStateMessage("Неверный формат номера телефона");
      });
  };

  const updateSocialMedia = async (id) => {
    await axios_instance
      .patch(`/socialProfile/update/${id}`, {
        name: newValue,
        social_media: choosenSocialMedia
          ? String(choosenSocialMedia)
          : String(props?.media?.id),
      })
      .then((response) => {
        setEditMode(!editMode);
        props.getMe();
      })
      .catch((error) => {
        setIsValid(false);
        setValueState(true);
        setValueStateMessage("Ошибка при изменении социальной сети");
      });
  };

  function isValidPhone(phone) {
    return /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/.test(phone);
  }

  function submitFormValidate() {
    if (props?.name.startsWith("phone_number")) {
      if (isValidPhone(newValue)) {
        if (props?.name.startsWith("phone_number_new")) {
          createPhoneNumber(newValue);
        } else {
          updatePhoneNumber(props?.id);
        }
      } else {
        setIsValid(false);
        setValueState(true);
        setValueStateMessage("Неверный формат номера телефона");
      }
    } else if (props?.name.startsWith("social_media")) {
      if (props?.name.startsWith("social_media_new")) {
        createSocialMedia();
      } else {
        updateSocialMedia(props?.id);
      }
    } else {
      if (props?.name === "email" && !emailValidator.validate(newValue)) {
        setIsValid(false);
        setValueState(true);
        setValueStateMessage("Неверный формат почты");
      } else {
        updateMe({ [props?.name]: newValue });
      }
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionSheetRef.current && !actionSheetRef.current.contains(event.target)) {
        setActionSheetIsOpen(false);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionSheetRef]);

  useEffect(() => {
    if (editMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode]);

  useEffect(() => {
    if (props?.name.startsWith("social_media")) {
      getSocialMedia();
    }
  }, [props.name]);

  return (
    <div className="user-profile-card-one-line">
      <span className="user-profile-card-one-line-title">{props?.title}</span>
      <div className="user-profile-card-one-line-input">
        {props?.name.startsWith("social_media") && editMode && (
          <ThemeProvider>
            <div
              id={"openActionSheetBtn"}
              onClick={() => {
                setActionSheetIsOpen(true);
              }}
            >
              {choosenSocialMedia || props?.media ? (
                <div className="user-profile-card-one-line-add_phone_number-btn">
                  <img
                    style={{ width: "100%" }}
                    src={`/static/sherpa/${
                      vkData?.id === choosenSocialMedia
                        ? "vk_logo"
                        : tgData?.id === choosenSocialMedia 
                        ? "telegram_logo"
                        : props?.media?.name === "Вконтакте"
                        ? "vk_logo"
                        : "telegram_logo"
                    }.svg`}
                  />
                </div>
              ) : (
                <Button style={{ width: "auto" }}>Выберите соцсеть</Button>
              )}
            </div>
            <ActionSheet
              ref={actionSheetRef}
              horizontalAlign="Center"
              placement="Center"
              verticalAlign="Center"
              opener={"openActionSheetBtn"}
              open={actionSheetIsOpen}
              onClose={() => {
                setActionSheetIsOpen(false);
              }}
            >
              <Button
                onClick={() => {
                  setChoosenSocialMedia(vkData?.id);
                  setActionSheetIsOpen(false);
                }}
              >
                <div className="user-profile-card-one-line-messenger-select-btn">
                  <img src={`/static/sherpa/vk_logo.svg`} alt="MESSENGER" />
                  Вконтакте
                </div>
              </Button>
              <Button
                onClick={() => {
                  setChoosenSocialMedia(tgData?.id);
                  setActionSheetIsOpen(false);
                }}
              >
                <div className="user-profile-card-one-line-messenger-select-btn">
                  <img
                    src={`/static/sherpa/telegram_logo.svg`}
                    alt="MESSENGER"
                  />
                  Телеграмм
                </div>
              </Button>
            </ActionSheet>
          </ThemeProvider>
        )}
        {props?.media?.name && !editMode && (
          <img
            src={`/static/sherpa/${
              props?.media?.name === "Вконтакте" ? "vk_logo" : "telegram_logo"
            }.svg`}
            onClick={() => {
              openInNewTab(`${props?.media?.domain}${newValue}`);
            }}
            className="user-profile-card-one-line-add_phone_number-btn"
          />
        )}
        <input
          ref={inputRef}
          disabled={!editMode}
          type={(props?.name === "birthday") & editMode ? "date" : "text"}
          className="user-profile-card-one-line-input-text"
          placeholder={props?.required ? "* Заполните обязательные поля" : null}
          value={newValue}
          onChange={(e) => {
            setNewValue(e?.target?.value);
            setIsValid(true);
            setValueState(false);
          }}
          onInput={(e) => {
            setNewValue(e?.target?.value);
            setIsValid(true);
            setValueState(false);
          }}
        />
        {(props?.changeable || isAdmin) && (
          <Button
            style={{ color: editMode ? "green" : "#505050" }}
            desibled={
              editMode &
              props.required &
              ((newValue === "") | (newValue === undefined))
            }
            design="Transparent"
            tooltip={editMode ? "Сохранить" : "Изменить"}
            onClick={() => {
              if (
                ((newValue !== props?.value) & (newValue !== "")) |
                (choosenSocialMedia !== undefined)
              ) {
                submitFormValidate();
              } else setEditMode(!editMode);
            }}
            icon={editMode ? "accept" : "edit"}
          />
        )}
      </div>
      <hr
        style={{
          width: "100%",
          border: isValid ? null : "1px solid rgb(255, 0, 0)",
        }}
      />
      {valueState && (
        <div className="user-profile-card-one-line-error-text">
          {valueStateMessage}
        </div>
      )}
      {props.name.startsWith("phone_number") && props.index === 0 && (
        <div className="user-profile-card-one-line-add_phone_number">
          <img
            src="/static/sherpa/plus.svg"
            onClick={() => {
              addPhoneNumberField();
            }}
            title="Добавить номер телефона"
            className="user-profile-card-one-line-add_phone_number-btn"
          />
          <span className="user-profile-card-one-line-add_phone_number-text">
            Указать дополнительный номер
          </span>
        </div>
      )}
      {props.name.startsWith("social_media") && props.index === 0 && (
        <div className="user-profile-card-one-line-add_phone_number">
          <img
            src="/static/sherpa/plus.svg"
            onClick={() => {
              addSocialMediaField();
            }}
            title="Добавить мессенджер"
            className="user-profile-card-one-line-add_phone_number-btn"
          />
          <span className="user-profile-card-one-line-add_phone_number-text">
            Добавить мессенджер
          </span>
        </div>
      )}
    </div>
  );
};

export default UserProfileCardOneLine;
