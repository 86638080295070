import React, { useEffect, useState } from "react";
import "./ChangePasswordDialog.css";
import RegistrationDialog from "../RegistrationDialog";
import { FlexBox, Button } from "@ui5/webcomponents-react";
import DialogInput from "../../dialog-input/DialogInput";
import axios_instance from "../../../../../../requests/config_defaults";
import ResultDialog from "../../result-dialog/ResultDialog";

const ChangePasswordDialog = ({ open, setOpen, setLoginIsOpen, email }) => {
  const [changePasswordResultIsOpen, setChangePasswordResultIsOpen] =
    useState(false);

  const [form, setForm] = useState({
    password: "",
    repeatPassword: "",
    personalDataProcessConfirm: false
  });

  const [formValueState, setFormValueState] = useState({
    password: null,
    repeatPassword: null,
    personalDataProcessConfirm: null,
  });

  const [formValueStateMessage, setFormValueStateMessage] = useState({
    password: "",
    repeatPassword: "",
    personalDataProcessConfirm: "",
  });

  const formInputs = [
    {
      type: "password",
      placeholder: "Укажите новый пароль",
      name: "password",
    },
    {
      type: "password",
      placeholder: "Повторите пароль",
      name: "repeatPassword",
    },
    {
      type: "checkbox",
      placeholder: <span className="personal-data-process-confirm-checkbox">Я согласен(а) на <a className={"personal-data-process-confirm-checkbox-link"}>обработку персональных данных</a></span>,
      name: "personalDataProcessConfirm"
    },
  ];

  function submitFormValidate(e) {
    let updated_form_value_state = { ...formValueState };
    let updated_form_value_state_message = { ...formValueStateMessage };
    let validation_status = true;
    for (let i in form) {
      if (!formValueState.hasOwnProperty(i)) {
        continue;
      }
      if (form[i] === "") {
        updated_form_value_state[i] = false;
        updated_form_value_state_message[i] = "Обязательное поле";
        validation_status = false;
      } else if (
        (i === "password" || i === "repeatPassword") &&
        form.password !== form.repeatPassword
      ) {
        updated_form_value_state[i] = false;
        updated_form_value_state_message[i] = "Пароли не совпадают";
        validation_status = false;
      }
    }
    setFormValueState(updated_form_value_state);
    setFormValueStateMessage(updated_form_value_state_message);

    if (validation_status) {
      passwordVerify();
    }
  }

  function resetFormFieldValueState(field) {
    if (!formValueState.hasOwnProperty(field)) {
      return;
    }
    let updated_form_value_state = { ...formValueState };
    let updated_form_value_state_message = { ...formValueStateMessage };
    updated_form_value_state[field] = null;
    updated_form_value_state_message[field] = "";
    setFormValueState(updated_form_value_state);
    setFormValueStateMessage(updated_form_value_state_message);
  }

  async function passwordVerify() {
    await axios_instance
      .put(
        "/password/verify",
        {
          email: email,
          password: form.password
        }
      )
      .then(response => {
        console.log(response);
        setOpen(false);
        setChangePasswordResultIsOpen(true);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div>
      {open && (
        <RegistrationDialog
          open={open}
          setOpen={setOpen}
          headerText="СБРОС ПАРОЛЯ"
          footerText="Продолжить"
          submitHandler={submitFormValidate}
          body={
            <FlexBox direction="Column" className="dialog-inputs-box">
              {formInputs.map((input) => (
                <DialogInput
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.name}
                  form={form}
                  setForm={setForm}
                  formValueState={formValueState}
                  formValueStateMessage={formValueStateMessage}
                  resetFormFieldValueState={resetFormFieldValueState}
                />
              ))}
            </FlexBox>
          }
        />
      )}
      {changePasswordResultIsOpen && (
        <ResultDialog
          open={changePasswordResultIsOpen}
          setOpen={setChangePasswordResultIsOpen}
          logo="/static/sherpa/mini_psg_logo.svg"
          headerText="Пароль успешно изменен!"
          body={
            <FlexBox
              alignItems="Center"
              direction="Column"
              className="dialog-inputs-box"
            >
              <FlexBox justifyContent="Center" style={{ width: "100%" }}>
                <Button
                  icon="arrow-right"
                  iconEnd
                  className="password-reset-dialog-btn"
                  onClick={() => {
                    setChangePasswordResultIsOpen(false);
                    setLoginIsOpen(true);
                  }}
                >
                  <span className="password-reset-dialog-btn-text">
                    Войти
                  </span>
                </Button>
              </FlexBox>
            </FlexBox>
          }
        />
      )}
    </div>
  );
};

export default ChangePasswordDialog;
