import React, { useEffect, useState } from "react";
import "./UserProfileNotifications.css";
import axios_instance from "../../../../requests/config_defaults";
import { SpaOutlined, SpaRounded } from "@mui/icons-material";
import { Switch } from "@ui5/webcomponents-react";
import { useAtom } from "jotai";
import { userNotificationsAtom } from "../../../../hoc/GlobalAtoms";

const UserProfileNotifications = (props) => {
  const [userData, setUserData] = useState(null);
  const [userNotifications, setUserNotifications] = useAtom(userNotificationsAtom);

  const getMe = async () => {
    await axios_instance
      .get("/getMe")
      .then((response) => {
        setUserData(response.data.user);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div className="user-profile-notifications-container">
      <div className="user-profile-notifications-content">
        <div className="user-profile-main-content-header">
          <h1 className="user-profile-main-content-header-title">
            Уведомления
          </h1>
          <span className="user-profile-main-content-header-text">
            Настройте уведомления так, чтобы Вам было удобно получать необходимую информацию.
          </span>
        </div>
        <div className="user-profile-notifications-content-body">
          <div className="user-profile-notifications-body-elem-container">
            <h3 className="user-profile-notifications-content-body-title">
              Поставьте отметку включения напротив пунктов, которые вы хотите получать
            </h3>
            {userNotifications.map(item => (
              <div className="user-profile-notifications-body-elem-container-items">
                <span>
                  {item.text}
                </span>
                <Switch
                  onChange={(event) => {
                    setUserNotifications(prevNotifications =>
                      prevNotifications.map(notification =>
                        notification.text === item.text
                          ? { ...notification, status: event.target.checked }
                          : notification
                      )
                    );
                  }}
                  checked={item.status} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserProfileNotifications };
