import React, { useState } from 'react'
import "./DialogFooter.css";
import { Bar, Button, FlexBox } from '@ui5/webcomponents-react';


const DialogFooter = ({ text, submitHandler, slot }) => {
    return (
        <FlexBox justifyContent="End" slot={slot} className="dialog-footer">
            <Button icon="arrow-right" iconEnd className="dialog-footer-button" onClick={submitHandler}>
                <span className="dialog-footer-text">{text}</span>
            </Button>
        </FlexBox>
    )
}

export default DialogFooter