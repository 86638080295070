import React, { useState, useEffect } from "react";
import "./UserRegistrationDialog.css";
import RegistrationDialog from "../RegistrationDialog";
import { FlexBox, Button } from "@ui5/webcomponents-react";
import DialogInput from "../../dialog-input/DialogInput";
import DialogSelect from "../../dialog-select/DialogSelect";
import axios_instance from "../../../../../../requests/config_defaults";
import ResultDialog from "../../result-dialog/ResultDialog";

const UserRegistrationDialog = ({ open, setOpen }) => {
  const [resultDialogIsOpen, setResultDialogIsOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const emailValidator = require("email-validator");
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    login: "",
    password: "",
    repeat_password: "",
    permission: "",
  });

  const [formValueState, setFormValueState] = useState({
    first_name: null,
    last_name: null,
    email: null,
    login: null,
    password: null,
    repeat_password: null,
    permission: null,
  });

  const [formValueStateMessage, setFormValueStateMessage] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    repeat_password: "",
    permission: "",
  });

  const formInputs = [
    {
      type: "text",
      placeholder: "Укажите имя пользователя",
      name: "first_name",
    },
    {
      type: "text",
      placeholder: "Укажите фамилию пользователя",
      name: "last_name",
    },
    {
      type: "text",
      placeholder: "Укажите отчество пользователя",
      name: "middle_name",
    },
    {
      type: "email",
      placeholder: "Укажите почту пользователя",
      name: "email",
    },
    {
      type: "text",
      placeholder: "Укажите логин пользователя",
      name: "login",
    },
    {
      type: "password",
      placeholder: "Укажите пароль пользователя",
      name: "password",
    },
    {
      type: "password",
      placeholder: "Повторите пароль пользователя",
      name: "repeat_password",
    },
  ];

  function submitFormValidate(e) {
    let updated_form_value_state = { ...formValueState };
    let updated_form_value_state_message = { ...formValueStateMessage };
    let validation_status = true;
    for (let i in form) {
      if (!formValueState.hasOwnProperty(i)) {
        continue;
      }
      if (form[i] === "") {
        updated_form_value_state[i] = false;
        updated_form_value_state_message[i] = "Обязательное поле";
        validation_status = false;
      } else if (i === "email" && !emailValidator.validate(form[i])) {
        updated_form_value_state[i] = false;
        updated_form_value_state_message[i] = "Неверный формат почты";
        validation_status = false;
      } else if (i === "personal_data_process_confirm" && form[i] === false) {
        updated_form_value_state[i] = false;
        validation_status = false;
      } else if (
        (i === "password" || i === "repeat_password") &&
        form.password !== form.repeat_password
      ) {
        updated_form_value_state[i] = false;
        updated_form_value_state_message[i] = "Пароли не совпадают";
        validation_status = false;
      } else {
        updated_form_value_state[i] = null;
        updated_form_value_state_message[i] = "";
      }
    }
    setFormValueState(updated_form_value_state);
    setFormValueStateMessage(updated_form_value_state_message);

    if (validation_status) {
      performRegistration();
    }
  }

  function resetFormFieldValueState(field) {
    if (!formValueState.hasOwnProperty(field)) {
      return;
    }
    let updated_form_value_state = { ...formValueState };
    let updated_form_value_state_message = { ...formValueStateMessage };
    updated_form_value_state[field] = null;
    updated_form_value_state_message[field] = "";
    setFormValueState(updated_form_value_state);
    setFormValueStateMessage(updated_form_value_state_message);
  }

  async function performRegistration() {
    await axios_instance
      .post(`/registration`, form)
      .then(({ data, status }) => {
        console.log(data);
        setOpen(false)
        setResultDialogIsOpen(true)
      })
      .catch(error => {
        if (error?.response?.data?.email[0] === "Пользователь с таким Email уже существует.") {
          setFormValueState({ ...formValueState, email: false })
          setFormValueStateMessage({ ...formValueStateMessage, email: "Пользователь с таким email уже существует" })
        }
      });
  }

  const getLegalEntityPermissionsList = async () => {
    await axios_instance.get(
      "/entity/permissions/list"
    )
      .then((response) => {
        setRoles(response.data.results);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    if (open) {
      getLegalEntityPermissionsList();
    }
  }, [open])

  return (
    <div>
      {open && (
        <RegistrationDialog
          open={open}
          setOpen={setOpen}
          headerText="РЕГИСТРАЦИЯ"
          footerText="ОТПРАВИТЬ"
          submitHandler={submitFormValidate}
          body={
            <FlexBox direction="Column" className="dialog-inputs-box">
              {formInputs.map((input) => (
                <DialogInput
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.name}
                  form={form}
                  setForm={setForm}
                  formValueState={formValueState}
                  formValueStateMessage={formValueStateMessage}
                  resetFormFieldValueState={resetFormFieldValueState}
                />
              ))}
              <DialogSelect
                options={roles}
                name="permission"
                placeholder="Выберите роль пользователя"
                form={form}
                setForm={setForm}
                formValueState={formValueState}
                formValueStateMessage={formValueStateMessage}
                resetFormFieldValueState={resetFormFieldValueState}
              />
            </FlexBox>
          }
        />
      )}
      {resultDialogIsOpen && (
        <ResultDialog
          open={resultDialogIsOpen}
          setOpen={setResultDialogIsOpen}
          headerText="Информация принята!"
          logo="/static/sherpa/mini_psg_logo.svg"
          body={
            <FlexBox
              alignItems="Center"
              justifyContent="Center"
              direction="Column"
              className="dialog-inputs-box"
            >
              <span className="result-dialog-text">
                Уведомление о регистрации сотрудника придет на указанную Вами
                почту
              </span>
              <Button
                className="result-dialog-btn"
                onClick={() => setResultDialogIsOpen(false)}
              >
                Готово
              </Button>
            </FlexBox>
          }
        />
      )}
    </div>
  );
};

export default UserRegistrationDialog;
