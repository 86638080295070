import "@ui5/webcomponents-icons/dist/search.js";
import { Button } from '@ui5/webcomponents-react';
import "./HeaderTop.css";
import MainPageMainContainer from "../../MainPageMainContainer/MainPageMainContainer";

const HeaderTop = () => {
    return (
        <div className="main-page-header-top">
            <MainPageMainContainer>
                <div className="main-page-header-top">
                    <div className="main-page-header-content">
                        <div className="main-page-header-left-side">
                            <a href="/" className="header-top-ref">
                                <img
                                    alt="PSG"
                                    className="header-top-ref-img"
                                    src={"/static/sherpa/red_arrow.svg"}
                                />
                                <div>
                                    Международные перевозки
                                </div>
                            </a>
                            <a href="/" className="header-top-ref">
                                <img
                                    alt="PSG"
                                    className="header-top-ref-img"
                                    src={"/static/sherpa/red_arrow.svg"}
                                />
                                <div>
                                    ВЭД консалтинг
                                </div>
                            </a>
                            <a href="/" className="header-top-ref">
                                <img
                                    alt="PSG"
                                    className="header-top-ref-img"
                                    src={"/static/sherpa/red_arrow.svg"}
                                />
                                <div>
                                    Информационные технологии
                                </div>
                            </a>
                        </div>
                        <div className="main-page-header-right-side">
                            <a href="/" className="header-top-ref email-icon">
                                <svg width="35" height="35" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17.5" cy="17.5" r="17.5" className="email-icon-circle" />
                                    <g clipPath="url(#clip0_244_38)">
                                        <path d="M6.72412 11.3965V23.6138L12.8327 17.5052L6.72412 11.3965Z" />
                                        <path d="M7.93799 10.1724L15.6716 17.906C16.6483 18.8828 18.3518 18.8828 19.3285 17.906L27.0621 10.1724H7.93799Z" />
                                        <path d="M20.5474 19.125C19.7344 19.9388 18.6517 20.3879 17.5 20.3879C16.3482 20.3879 15.2655 19.9388 14.4526 19.125L14.0517 18.7241L7.94824 24.8276H27.0517L20.9482 18.7241L20.5474 19.125Z" />
                                        <path d="M22.1672 17.5052L28.2759 23.6138V11.3965L22.1672 17.5052Z" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_244_38">
                                            <rect width="21" height="15" transform="translate(7 10)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <div>
                                    mail@psg.su
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </MainPageMainContainer>
        </div>

    )
}

export default HeaderTop