import "./UserProfileSideBar.css";
import { NavLink } from "react-router-dom";

const UserProfileSideBar = ({ activePath }) => {
  return (
    <div className="user-profile-side-bar-container">
      <NavLink 
        to="/profile/main"
        className={`user-profile-side-bar-elem ${activePath === "main" ? 'active' : ''}`}
      >
        Главная
      </NavLink>
      <NavLink
        to="/profile/info"
        className={`user-profile-side-bar-elem ${activePath === 'info' ? 'active' : ''}`}
      >
        Личная информация
      </NavLink>
      <NavLink 
        to="/profile/notifications"
        className={`user-profile-side-bar-elem ${activePath === 'notifications' ? 'active' : ''}`}
      >
        Уведомления
      </NavLink>
      <NavLink 
        to="/profile/settings"
        className={`user-profile-side-bar-elem ${activePath === 'settings' ? 'active' : ''}`}
      >
        Настройки пользователя
      </NavLink>
    </div>
  );
};

export default UserProfileSideBar;
