import "@ui5/webcomponents-icons/dist/search.js";
import { Button } from '@ui5/webcomponents-react'
import "./Header.css";
import MainPageMainContainer from "../MainPageMainContainer/MainPageMainContainer"
import { useNavigate } from "react-router-dom";
import { checkIsAuthenticated } from "../../../../requests/authorization"
import { useEffect, useState } from "react";
import { emailAtom } from "../../../../hoc/GlobalAtoms";
import { useAtom } from "jotai";
import HeaderBottom from "./header-bottom/HeaderBottom";
import HeaderTop from "./header-top/HeaderTop";

const Header = (props) => {
    return (
        <>
            <HeaderTop />
            <HeaderBottom isAuth={props?.isAuth} setIsAuth={props?.setIsAuth}/>
        </>
    )
}

export default Header