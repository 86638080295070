import "./TrackDelivery.css";
import { Button, FlexBox, Icon, Input, SuggestionItem } from "@ui5/webcomponents-react";
import { useState, useEffect, useRef } from "react";
import "@ui5/webcomponents-icons/dist/flight.js";

const TrackDelivery = () => {
    const [inputCode, setInputCode] = useState("");
    const [inputError, setInputError] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [activeProduct, setActiveProduct] = useState("")

    const [searchError, setSearchError] = useState(false)
    const [searchErrorMessage, setSearchErrorMessage] = useState("")

    const [buttonWidth, setButtonWidth] = useState('70%');
    const [showClearIcon, setShowClearIcon] = useState(false);
    const [buttonDisplay, setButtonDisplay] = useState('block');
    const [inputFocus, setInputFocus] = useState(false)

    const input = useRef(null);

    const handleClickInput = () => {
        input.current.focus();
    };

    return (
        <div className="main-page-body-container-content-right-body">
            <FlexBox justifyContent="Start" className="track-delivery-header">
                <div>ОТСЛЕДИТЬ ГРУЗ</div>
            </FlexBox>
            <FlexBox justifyContent="SpaceBetween" className="track-delivery-body">
                <div className="track-delivery-body-input" onClick={() => {handleClickInput()}}>
                    <Icon name="search" className="track-delivery-input-icon" />
                    <input
                        className="track-delivery-input"
                        placeholder="Введите номер отслеживания"
                        ref={input}
                    />
                </div>
                <Button icon="flight" className="track-delivery-button">
                    Отследить
                </Button>
            </FlexBox>
            <FlexBox justifyContent="Start" className="track-delivery-bottom">
                <div className="track-delivery-bottom-text">Например: 0042-9492-9412</div>
            </FlexBox>

        </div>
    );
};

export default TrackDelivery;
