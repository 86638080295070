import React, { useEffect, useState } from 'react'
import "./DialogHeader.css";
import { Button, FlexBox } from '@ui5/webcomponents-react';


const DialogHeader = ({ text, setOpen, slot, logo }) => {

    return (
        // <FlexBox direction="Column" slot={slot} className="dialog-header">
        <FlexBox direction="Column" className="dialog-header">
            <FlexBox justifyContent="End">
                <Button icon="decline" onClick={() => setOpen(false)} design="Transparent" className="dialog-header-btn" />
            </FlexBox>
            {logo &&
                <FlexBox justifyContent="Center" className="dialog-header-logo">
                    <img src={logo} alt="logo" />
                </FlexBox>
            }
            <FlexBox justifyContent="Center" className="dialog-header-title">
                {text}
            </FlexBox>
        </FlexBox>
    )
}

export default DialogHeader