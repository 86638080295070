import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./UserProfile.css";
import { Outlet } from "react-router-dom";
import UserProfileHeader from "./UserProfileHeader/UserProfileHeader";
import UserProfileSideBar from "./UserProfileSideBar/UserProfileSideBar";

const UserProfile = (props) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState("");

  useEffect(() => {
    const path = location.pathname.split("/profile/")[1];
    console.log("Active Path:", path);
    setActivePath(path || null);
  }, [location]);

  return (
    <div className="user-profile-container">
      <div className="user-profile-main">
        <UserProfileHeader
          className="user-profile-header"
        />
        <div className="user-profile-content">
          <UserProfileSideBar activePath={activePath}/>
          <div className="user-profile-content-main-container">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserProfile };
